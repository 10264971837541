<template>
  <div>
    <el-button type="primary" size="small" @click="addInfo()">新增</el-button>
    <div class="Mtp15">
      <el-row :gutter="10" class="F12" v-if="list.length > 0">
        <el-col :span="3">规格</el-col>
        <el-col :span="2">单位</el-col>
        <el-col :span="4">售价</el-col>
        <el-col :span="4">库存</el-col>
        <el-col :span="3">交货方式</el-col>
        <el-col :span="3">是否订单确认</el-col>
        <el-col :span="2">图片</el-col>
      </el-row>
      <el-row :gutter="10" class="Mtp5" v-for="(item, index) in list" :key="index" align="bottom">
        <el-col :span="3">
          <el-input size="small" placeholder="规格" v-model="item.typeName" clearable></el-input>
        </el-col>
        <el-col :span="2">
          <el-input size="small" placeholder="单位" v-model="item.sellUnit"></el-input>
        </el-col>
        <el-col :span="4">
          <el-input-number size="small" placeholder="售价" v-model="item.supplierPrice" :min="0" :precision="2"></el-input-number>
        </el-col>
        <el-col :span="4">
          <el-input-number size="small" placeholder="库存" :precision="3" v-model="item.inventory" :min="0"></el-input-number>
        </el-col>
        <el-col :span="3">
          <el-select v-model="item.delivery" placeholder="请选择交货方式" size="small" style="width: 100%">
            <el-option :label="list.label" :value="list.value" v-for="list in modeOfDelivery" :key="list.value"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-switch v-model="item.whetherAffirm" active-text="是" inactive-text="否" active-value="Y" inactive-value="N" size="small">
          </el-switch>
        </el-col>
        <el-col :span="2" v-if="item.logo != ''">
          <el-image style="width: 30px; height: 30px; border: 1px solid #dcdfe6" class="curPointer floatL Mrt5" :src="item.logo" :fit="'cover'" @click="handlePictureCardPreview(item.logo)"></el-image>
          <el-link @click="delPic(index)">删除图片</el-link>
          <!-- <el-button type="text" @click="delPic(index)">删除图片</el-button> -->
        </el-col>
        <el-col :span="2" v-else>
          <buttonUpload ref="buttonUpload" @uploadSuccess="uploadSuccess" :index="index" moduleType="01"></buttonUpload>
        </el-col>
        <el-col :span="2" style="padding-left: 15px;">
          <el-button type="danger" icon="el-icon-delete" circle size="small" @click="delInfo(index)"></el-button>
        </el-col>
      </el-row>
    </div>
    <el-dialog :visible.sync="dialogVisible" size="tiny" top="5vh">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import buttonUpload from "@/components/upload/buttonUpload";
import { modeOfDelivery } from "@/util/util";

// 商品销售信息
export default {
  data() {
    return {
      modeOfDelivery: this.formatList(),
      dialogImageUrl: "",
      dialogVisible: false
    };
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { buttonUpload },
  mounted() {},
  methods: {
    addInfo() {
      this.list.push({
        // 商品销售分类名称
        typeName: "",
        // 商品销售备注
        typeRemark: "",
        // 销售单位
        sellUnit: "",
        // 商品销售价格
        supplierPrice: "",
        // 商品库存数量
        inventory: 0,
        // 商品销售类别图片
        logo: ""
      });
    },
    // 删除信息
    delInfo(index) {
      this.list.splice(index, 1);
    },
    // 移除图片
    delPic(index) {
      this.$set(this.list[index], "logo", "");
    },
    // 图片上传成功
    uploadSuccess(data) {
      this.list[data.index].logo = data.filePath;
    },
    // 预览
    handlePictureCardPreview(path) {
      this.dialogImageUrl = path;
      this.dialogVisible = true;
    },

    // 清洗交货方式下拉value值为字符串
    formatList() {
      return modeOfDelivery.map(v => {
        v.value = String(v.value);
        return v;
      });
    }
  }
};
</script>
