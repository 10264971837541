<template>
  <div>
    <relative-content>
      <div slot="menu">
        <el-button
          type="primary"
          icon="el-icon-back"
          size="small"
          @click="upStep()"
          >上一步</el-button
        >
        <span class="F16 Mlf10"> 商品信息 </span>
      </div>
      <div slot="title">基础信息</div>
      <div slot="relat">
        <div class="relat Plf40 Prt40">
          <div class="Ptp20 form">
            <el-row :gutter="1">
              <el-col :span="2">
                <div> 类目属性</div>
              </el-col>
              <el-col :span="20">
                <div class="typepropertyform main-bg Prt30 Ptp30 Pbm20 Plf20">
                  <el-form label-position="right">
                    <el-row :gutter="30">
                      <el-col
                        :span="12"
                        v-for="item in basicInfo.propertyList"
                        :key="item.id"
                      >
                        <el-form-item
                          :label="item.propertyName + '：'"
                          label-width="70px"
                        >
                          <el-input
                            size="small"
                            v-model="item.propertyValue"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="Ptp35">
            <el-row :gutter="1">
              <el-col :span="2"><span style="color: #F56C6C">*</span> 销售信息 </el-col>
              <el-col :span="22">
                <salesInfo
                  ref="salesInfo"
                  :list="basicInfo.sellList"
                ></salesInfo>
              </el-col>
            </el-row>
          </div>
          <!-- <div class="Ptp35">
            <el-row :gutter="1">
              <el-col :span="4">
                <div>是否需要订单确认</div>
              </el-col>
              <el-col :span="20">
                <el-switch
                  v-model="basicInfo.whetherAffirm"
                  active-text="是"
                  inactive-text="否"
                  active-value="Y"
                  inactive-value="N"
                >
                </el-switch>
              </el-col>
            </el-row>
          </div> -->
          <graphicDescription ref="graphicDescription"></graphicDescription>
        </div>
      </div>
      <div slot="upstep" class="button-group">
        <el-button type="info" @click="upStep()">上一步</el-button>
      </div>
      <div slot="nextstep" class="button-group">
        <el-button type="primary" @click="nextStep(1)" :loading="loading"
          >保存商品</el-button
        >
        <el-button type="primary" @click="nextStep(2)" :loading="loading"
          >保存并提交审核</el-button
        >
      </div>
    </relative-content>
  </div>
</template>
<script>
import RelativeContent from "@/components/relativeContent";
import graphicDescription from "./GraphicDescription";
import salesInfo from "@/components/product/salesInfo";

import {
  getGoodsInfoById,
  getGoodsParentProperty,
  saveOrUpdateSell,
  goodsSumbitApply,
} from "@/api/product";
export default {
  components: {
    RelativeContent,
    graphicDescription,
    salesInfo,
  },
  data() {
    return {
      // 商品ID
      id: "",
      // 商品基础信息
      basicInfo: {},
      typePropertyForm: {
        brand: "",
        material: "",
        origin: "",
        isVideo: "",
        buildingType: "",
        isElectric: "",
      },
      goodNumber: 2,
      loading: false,
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    // 根据商品ID获取整个商品的信息
    this.getGoodsInfoById();
  },
  methods: {
    // 根据商品ID获取整个商品的信息
    getGoodsInfoById() {
      getGoodsInfoById(this.id).then((res) => {
        this.basicInfo = res.data;
        // 根据商品类目ID获取类目底下的属性
        this.getGoodsParentProperty(this.basicInfo.goodsTypeId);
        this.$nextTick(() => {
          this.$refs.graphicDescription.setEditorData(
            unescape(this.basicInfo.goodsDetail)
          );
        });
      });
    },
    // 根据商品类目ID获取类目底下的属性
    getGoodsParentProperty(id) {
      getGoodsParentProperty(id).then((res) => {
        let propertyList = [];
        for (let x = 0; x < res.data.length; x++) {
          let result = res.data[x];
          propertyList.push({
            propertyName: result.categoryTypeProperty,
            propertyId: result.id,
            propertyValue: "",
          });
        }

        // return false
        // 循环该类目属性
        for (let x = 0; x < this.basicInfo.propertyList.length; x++) {
          let r = this.basicInfo.propertyList[x];
          // 循环该商品下的属性
          for (let y = 0; y < propertyList.length; y++) {
            let response = propertyList[y];
            if (response.propertyId == r.propertyId) {
              response.propertyValue = r.propertyValue;
            }
          }
        }
        this.$set(this.basicInfo, "propertyList", propertyList);
      });
    },
    upStep() {
      let query = this.$route.query;
      delete query.id;
      this.$router.push({
        name: "GoodsReleased",
        query: {
          ...query,
          id: this.id,
        },
      });
    },
    /*
     *@description: type = 1 保存商品、type = 2 保存并提交审核 按钮事件
     *@modifyContent:
     *@author: Jerry
     *@date: 2021-03-09 10:31:08
     */
    nextStep(type) {
      console.log('this.basicInfo', this.basicInfo.sellList)
      this.basicInfo.goodsDetail = escape(
        this.$refs.graphicDescription.getEditorData()
      );
      if(this.basicInfo.sellList.length === 0 ){
        this.$message({
          type: "error",
          message: "销售信息不能为空",
        })
        return
      }
      for (let i = 0; i < this.basicInfo.sellList.length; i++) {
        if (this.basicInfo.sellList[i].logo == "") {
          this.$message({
            type: "error",
            message: "规格图片不能为空",
          });
          return false;
        }
        // if (this.basicInfo.sellList[i].supplierPrice === 0){
        //   this.$message({
        //     type: "error",
        //     message: "售价不能为0",
        //   });
        //   return false;
        // }
      }
      this.loading = true;
      saveOrUpdateSell(this.basicInfo)
        .then((res) => {
          this.loading = false;
          if (res.success) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            if (type == 2) {
              this.submitExamine(this.basicInfo.id);
            } else {
              this.goBackList(1000);
            }
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // 提交审核
    submitExamine(id) {
      goodsSumbitApply(id).then((res) => {
        if (res.success) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.goBackList(1000);
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    // 返回
    goBackList(time) {
      let query = this.$route.query;
      delete query.id;
      setTimeout(() => {
        this.$router.push({
          name: "GoodsList",
          query: {
            ...query,
          },
        });
      }, time);
    },
  },
};
</script>
<style scoped lang="scss">
.relat {
  .jicu {
    .text {
      line-height: 40px;
    }
    .el-button {
      padding: 5px 5px;
    }
  }
  .form {
    .typepropertyform {
      border: 1px solid #cccccc;
    }
  }
}
.button-group .el-button {
  padding-left: 61px;
  padding-right: 61px;
}
</style>
