<template>
  <div class="certificationcontent Mtp25 Pbm100">
    <el-container class="wrap">
      <el-header>
        <div class="F16 bg_fff Plf20 Ptp15 Pbm15 orange">
          <slot name="menu"> </slot>
        </div>
      </el-header>
      <el-main class="Mtp35">
        <div class="container bg_fff">
          <div class="title b-bottom">
            <slot name="title"> </slot>
          </div>
          <div class="relativecontent">
            <slot name="relat"></slot>
          </div>
          <div class="relativefooter">
            <div class="btn Mrt20">
              <slot name="upstep">
                <!-- <el-button type="info">上一步</el-button> -->
              </slot>
            </div>
            <div class="btn">
              <slot name="nextstep">
                <!-- <el-button type="primary">下一步</el-button> -->
              </slot>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  components: {},
  name: "Certificationcontent",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.certificationcontent {
  .el-header {
    padding: 0px !important;
    height: 25px !important;
  }
  .el-main {
    padding: 20px 0px !important;
    .container {
      .title {
        padding: 20px;
      }
      .relativecontent {
        border-bottom: 1px solid #ff5b0c;
      }
      .relativefooter {
        padding: 15px;
        text-align: center;
        .btn {
          display: inline-block;
        }
      }
    }
  }
}
</style>