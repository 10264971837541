<template>
  <div>
    <el-upload
      action="/shop/fileservice/uploadFile"
      :on-success="handleAvatarSuccess"
      :headers="token"
      :data="{ moduleType: moduleType }"
    >
      <el-button size="mini" type="primary">上传图片</el-button>
    </el-upload>
  </div>
</template>

<script>
import { getToken } from "@/util/auth";
export default {
  data() {
    return {
      token: {}
    }
  },
  props: {
    moduleType: {
      type: String,
      default: () => {
        return "01";
      },
    },
    index: {
      type: Number,
      default: 0,
    }
  },
  mounted() {
    this.token = {
      "Blade-Auth": "bearer " + getToken(),
    };
  },
  methods: {
    //上传成功回调
    handleAvatarSuccess(res) {
      let data = {
        filePath: res.data[0].filePath,
        index: this.index
      }
      this.$emit('uploadSuccess', data)
    },
  }
}
</script>